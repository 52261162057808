@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Coda:wght@400;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
	}
}


@font-face {
	font-family: 'altone_b';
	src: local('altone-bold'), url('./assets/fonts/Altone-Bold.ttf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'altone_sb';
	src: local('altone-semibold'), url('./assets/fonts/Altone-SemiBold.ttf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'altone_m';
	src: local('altone-medium'), url('./assets/fonts/Altone-Medium.ttf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'altone_r';
	src: local('altone-regular'), url('./assets/fonts/Altone-Regular.ttf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'dmsans_r';
	src: local('DMSans'), url('./assets/fonts/DMSans-Regular.ttf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'dmsans_m';
	src: local('DMSans-medium'), url('./assets/fonts/DMSans-Medium.ttf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'dmsans_b';
	src: local('DMSans-bold'), url('./assets/fonts/DMSans-Bold.ttf') format('woff2');
	font-display: swap;
}

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: 'rgba(255, 255, 255, 0)';
}

#pin-0,
#pin-1,
#pin-2,
#pin-3,
#pin-4,
#pin-5,
#confirm-pin-0,
#confirm-pin-1,
#confirm-pin-2,
#confirm-pin-3,
#confirm_pin-4,
#confirm_pin-5 {
	-webkit-text-security: disc;
}

/* Make clicks pass-through */
#nprogress {
	pointer-events: none;
	background-color: #F7F9FB;
}

/* #cvv2>iframe {
		margin-top: 4px;
		width: 60px;
		height: 10px;
		border: none;
		background-color: #928;
		border-radius: 4px;
		padding: 2px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
} */
#cardNumber_modal>iframe {
	height: 20px
}

/* #cvv2>iframe, */
#cardNumber>iframe,
#cvv2_modal>iframe,
#copy-number>iframe,
#copy-cvv2>iframe {
	height: 20px;
}

#cvv2_modal>iframe,
#cardNumber_modal>iframe {
	width: 100%;
}

#copy-number,
#copy-cvv2 {
	width: 40px;
}

#result .css-4ocfrt {
	text-align: right;
	background-color: #028 !important;
}

#nprogress .bar {
	background: #6463FD;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 4px;
}

#nprogress .spinner {
	display: none;
}

/* Fancy blur effect */
#nprogress .peg {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #ffffff, 0 0 5px #ffffff;
	opacity: 1;

	-webkit-transform: rotate(3deg) translate(0px, -4px);
	-ms-transform: rotate(3deg) translate(0px, -4px);
	transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
	overflow: hidden;
	position: relative;
}

.nprogress-custom-parent #nprogress,
.nprogress-custom-parent #nprogress .bar {
	position: absolute;
}

.overlay {
	width: 100%;
	height: 100%;
	background: rgba(6, 6, 40, 0.3);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
	/* filter: blur(20px); */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: none;
}

input::placeholder {
	text-transform: capitalize;
}


.transition_left {
	opacity: 1;
	transform: translateX(0px);
	transition: 1s;

	-webkit-animation: left_animation 300ms linear;
	animation: left_animation 300ms linear;
}

@keyframes left_animation {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}

	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

.transition_right {
	opacity: 1;
	transform: translateX(0px);
	transition: 1s;

	-webkit-animation: right_animation 300ms linear;
	animation: right_animation 300ms linear;
}

@keyframes right_animation {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}

	100% {
		opacity: 1;
		transform: translateX(0px);
	}
}

.modal {
	position: fixed;
	z-index: 20;

	/* Center the modal */
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%) scale(1);

	-webkit-animation: modal_animation 300ms linear;
	animation: modal_animation 300ms linear;
}

@keyframes modal_animation {
	0% {
		opacity: 0;
		transform: translateX(-50%) translateY(-50%) scale(.8);
	}

	100% {
		opacity: 1;
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
}

.bottom_modal {
	position: fixed;
	z-index: 20;

	/* Center the modal */
	bottom: 1%;
	left: 50%;
	transform: translateX(-50%) translateY(-1%) scale(1);

	-webkit-animation: bottom_modal_animation 300ms linear;
	animation: bottom_modal_animation 300ms linear;
}

@keyframes bottom_modal_animation {
	0% {
		opacity: 0;
		transform: translateX(-50%) translateY(-1%) scale(.8);
	}

	100% {
		opacity: 1;
		transform: translateX(-50%) translateY(-1%) scale(1);
	}
}

.success {
	opacity: 1;
	transition: 1s;
	transform: scale(1);

	-webkit-animation: success_animation 300ms linear;
	animation: success_animation 300ms linear;
}

@keyframes success_animation {
	0% {
		opacity: 0;
		transform: scale(.5);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.spinner {
	display: block;
}

.spinner-icon {
	width: 18px;
	height: 18px;
	box-sizing: border-box;

	border: solid 2px #5034C4;
	border-top-color: #ffffff;
	border-left-color: #ffffff;
	border-radius: 50%;

	-webkit-animation: spinner 400ms linear infinite;
	animation: spinner 400ms linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

/* Virtual Card */
.scene {
	display: inline-block;
	width: 100%;
	perspective: 600px;
}

.card {
	position: relative;
	transform-style: preserve-3d;
	transform-origin: center right;
	transition: transform 1s;
}

.card.is-flipped {
	transform: translateX(-100%) rotateY(-180deg);
}

.card__face {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
}

.card__face--back {
	transform: rotateY(180deg);
}

/* Date picker */
.date-picker {
	height: fit-content;
}

.date-picker>div>div:nth-child(2) {
	width: 100%;
	position: unset;
}

.date-picker>div>div:nth-child(2)>div,
.date-picker>div>div:nth-child(2)>div>div:nth-child(2)>div:nth-child(2) {
	width: 100%;
}

*:focus:not(.focus-visible) {
	outline: none;
}

.coda-regular {
	font-family: "Coda", serif;
	font-weight: 400;
	font-style: normal;
}

.coda-extrabold {
	font-family: "Coda", serif;
	font-weight: 800;
	font-style: normal;
}

.react-datepicker {
	width: 350px;
	border: none !important;
	background-color: #F7F7F8 !important;
	font-family: 'Verdana','Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
}

h2.react-datepicker__current-month {
	color: #6D6D6F !important;
	font-size: 14px;
	font-weight: normal;
	line-height: 21px;
}

.react-datepicker__day-names {
	width: 100%;
	padding: 10px 0px;
}

.react-datepicker__day--selected {
	background-color: #5034C4 !important;
	color: #FFF
}

.react-datepicker__month-container {
	width: 100%;
}

.react-datepicker__header {
	width: 100%;
	background-color: #F7F7F8 !important;
	border: none !important;
}
